import React from "react";
import {
  Card,
  Table,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Label,
  Row,
  Col,
  Spinner,
  Container,
} from "reactstrap";
import PurchaseTicketModal from "../../../Components/modal/PurchaseTicketModal";
import { QRCodeSVG } from "qrcode.react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import TicketGiftModal from "../../../Components/modal/TicketGiftModal";
import moment from "moment";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import QueryString from "qs";
import Navbar from "../../../Components/Common/navbar";
import { getAccessToken } from "../../../api/auth";
import { getTicektListByPage, postTicketUseById } from "../../../api/service";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  padding: 10px 20px;
  /* max-width: 480px; */
  /* box-shadow: 3px 5px 5px hsl(0deg 0% 0% / 0.22); */
  background-color: white;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const FilterBadge = styled.div`
  border-radius: 100px;
  padding: 0.4rem 0.8rem;
  user-select: none;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#f6475f" : "#e9ecef")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  font-family: "Gmarket-M";
  font-size: 12px;
`;

function TicketPurchaseList(props) {
  const navigate = useNavigate();

  const [modalBackdrop, setModalBackdrop] = React.useState(false);
  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }
  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const [ticketModal, setTicketModal] = React.useState(false);
  const [selectOrder, setSelectOrder] = React.useState({});
  const [orders, setOrders] = React.useState([]);
  const [giftModal, setGiftModal] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(false);
  const [queryString, setQueryString] = React.useState({
    filter_site: SUBDOMAIN,
    filter_state: "사용안함",
  });

  React.useEffect(() => {
    setdata();
  }, [queryString]);

  const setdata = async (page) => {
    try {
      if (!page) page = 1;
      const accessToken = getAccessToken();
      if (accessToken) {
        setIsLoading(true);

        let _queryString = QueryString.stringify(queryString);
        if (_queryString) _queryString = `&${_queryString}`;
        else _queryString = "";

        const response = await getTicektListByPage({
          page: page,
          queryString: _queryString,
        });

        const data = response.data;
        // console.log("fetching tickets", data);
        setHasMore(data.hasMore);

        let _tickets = data.results;
        _tickets = _tickets.sort((a, b) => {
          return a.state === "사용안함" && !a.gifted ? -1 : +1;
        });
        _tickets = _tickets.map((ticket) => {
          return {
            ...ticket,
            remaining: moment(ticket.expiration_date)
              .startOf("day")
              .diff(moment().startOf("day"), "days"),
          };
        });

        if (page === 1) {
          setPage(1);
          setOrders(_tickets);
        } else {
          setPage(page);
          setOrders((prev) => [...prev, ..._tickets]);
        }
      } else {
        navigate(`/`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const ticketUse = () => {
    console.log("ticket use");

    postTicketUseById(selectOrder.id)
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        if (data.result === "ok") {
          alert("사용 요청이 처리 되었습니다.");
          setModalBackdrop(false);
          // navigate(0);
          setdata();
        } else {
          alert("사용 요청이 처리되지 않았습니다.");
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <React.Fragment>
      <div
        className="layout-wrapper landing"
        style={{ backgroundColor: "#fff9fa" }}
      >
        <div
          style={{
            maxWidth: "640px",
            margin: "0 auto",
            backgroundColor: "#fff",
          }}
        >
          <Navbar />
          <Container
            fluid
            style={{
              minHeight: "100vh",
              backgroundColor: "white",
              paddingBottom: "100px",
            }}
          >
            <Row className="mt-4">
              {/* <div className="d-flex justify-content-between">
                    <div>
                        <Input className="voucher-check-input-all" type="checkbox"
                            id="voucher-check-all"
                            onChange={() => {
                                toggleCheckAll()
                                changeCheckedRecords()
                            }}
                        />
                        <Label htmlFor="voucher-check-all" className="form-label fs-14" style={{ marginLeft: "6px" }}>전체선택</Label>
                    </div>
                    {modalDatas.length > 1 ? <Button onClick={() => {
                        setGiftModal(true);
                    }}>한번에 선물하기</Button> : null}
                </div> */}

              <div
                className="d-flex gap-2"
                style={{ paddingLeft: "30px", marginBottom: "20px" }}
              >
                <FilterBadge
                  active={queryString.filter_state === ""}
                  onClick={() => {
                    setQueryString({ ...queryString, filter_state: "" });
                  }}
                >
                  전체
                </FilterBadge>
                <FilterBadge
                  active={queryString.filter_state === "사용안함"}
                  onClick={() => {
                    setQueryString({
                      ...queryString,
                      filter_state: "사용안함",
                    });
                  }}
                >
                  사용가능
                </FilterBadge>
                <FilterBadge
                  active={queryString.filter_state === "사용함"}
                  onClick={() => {
                    setQueryString({ ...queryString, filter_state: "사용함" });
                  }}
                >
                  완료
                </FilterBadge>
                <FilterBadge
                  active={queryString.filter_state === "만료됨"}
                  onClick={() => {
                    setQueryString({ ...queryString, filter_state: "만료됨" });
                  }}
                >
                  만료
                </FilterBadge>
              </div>

              <Col className="col-12 d-flex flex-wrap justify-content-center gap-4">
                {(orders || []).map((order, key) => (
                  <Col
                    lg={12}
                    xxl={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={order.id}
                    className=""
                  >
                    <Item
                      // visible={!order.gifted}
                      visible={true}
                      className="bg-white"
                    >
                      <div
                        className="d-flex justify-content-between px-2 border-bottom"
                        onClick={() => {
                          if (order.gifted) return;
                          setSelectOrder(order);
                          toggleBackdrop();
                        }}
                      >
                        <div
                          className="card-title pt-3"
                          style={{ fontWeight: "600" }}
                        >
                          {order.name}
                        </div>
                        {!order.gifted && order.state === "사용안함" ? (
                          <p className="fw-bold mt-2 btn btn-primary">
                            사용가능
                          </p>
                        ) : (
                          <p className="text-muted pt-3">
                            {order.state === "사용함" ? "사용완료" : "사용불가"}
                          </p>
                        )}
                      </div>
                      <div
                        className="d-flex py-3 align-items-center justify-content-center"
                        onClick={() => {
                          if (order.gifted) return;
                          setSelectOrder(order);
                          toggleBackdrop();
                        }}
                      >
                        {/* <div className="avatar-md mx-2">
                                                <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                                    <img src={item.image} alt="" className="img-fluid" />
                                                </div>
                                            </div> */}
                        <div className="mt-1">
                          {/* <div className="card-text text-muted">{order.name + '(' + order.bundleCount + ')'}</div> */}
                          {/* <div className="card-text text-muted">{order.orderDate}</div> */}
                          {/* <div className="card-text text-muted">기간: {order.expiration_date} 까지</div> */}
                          <div className="card-text mt-2">
                            {order.remaining > 0
                              ? `잔여일수 ${order.remaining}일`
                              : "기간만료"}
                          </div>
                          {/* <p className="card-text text-muted">자세히 보기</p> */}
                        </div>
                      </div>
                      {/* {order.bundleCount} */}
                      {order.bundleCount && order.bundleCount > 1 && !order.can_refund_each ? (
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ paddingBottom: "8px" }}
                        >
                          <div
                            style={{
                              paddingInline: "24px",
                              fontSize: "12px",
                              color: "#888",
                              width: "95%",
                              textAlign: "center",
                            }}
                          >
                            본 티켓은 행사 상품으로 전체 환불만 가능합니다.
                            <br />
                            묶음 중 1장이라도 사용, 선물 시 미사용분에 대해서도
                            환불이 불가능합니다.
                          </div>
                        </div>
                      ) : null}
                      {!order.gifted && order.state === "사용안함" ? (
                        <div className="d-flex w-100 flex-column align-items-center px-5 py-3">
                          <div className="d-flex justify-content-center gap-2 pb-2">
                            {/* <Button
                        style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
                        color="success"
                        onClick={(e) => {
                          setSelectOrder(order);
                          setGiftModal(true);
                        }}
                      >
                        선물하기
                      </Button> */}
                            <Button
                              color="danger"
                              style={{
                                fontFamily: "Gmarket-M",
                                backgroundColor: "#ff5a5f",
                                borderColor: "#ff5a5f",
                              }}
                              onClick={(e) => {
                                setSelectOrder(order);
                                setTicketModal(true);
                              }}
                            >
                              환불
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex w-100 flex-column align-items-center px-5 pb-3">
                          {order.gift && order.gift["username"] ? (
                            <div className="card-text text-muted">
                              받은이: {order.gift["username"]}
                            </div>
                          ) : null}

                          {!!order.gifted ? <span>선물완료</span> : null}
                          <span style={{ margin: "8px" }}></span>
                          {/* {order.gifted === "선물발신" ? (
                      <Button
                        color="danger"
                        onClick={(e) => {
                          handelCancelGift(order.id);
                        }}
                      >
                        선물취소
                      </Button>
                    ) : null} */}
                        </div>
                      )}
                    </Item>
                  </Col>
                ))}

                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "50vh" }}
                  >
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : null}

                {hasMore ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ alignItems: "center", margin: "20px" }}
                  >
                    <Button color="primary" onClick={() => setdata(page + 1)}>
                      더보기
                    </Button>
                  </div>
                ) : null}

                {!isLoading && orders.length === 0 ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ alignItems: "center", height: 150 }}
                  >
                    티켓구매 내역 없음
                  </div>
                ) : null}
              </Col>

              <Modal
                isOpen={modalBackdrop}
                toggle={() => {
                  setSelectOrder({});
                  toggleBackdrop();
                }}
                backdrop={"static"}
                id="staticBackdrop"
                centered
              >
                <ModalHeader>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      setSelectOrder({});
                      setModalBackdrop(false);
                    }}
                    aria-label="Close"
                  ></button>
                </ModalHeader>
                <ModalBody className="text-center p-5">
                  <div className="mt-4">
                    {/* <h4 className="mb-3">{modalData.uid}</h4> */}
                    <QRCodeSVG value={selectOrder.id} />
                    <h5 className="modal-title m-2" id="staticBackdropLabel">
                      {selectOrder.name}
                    </h5>
                    <hr></hr>
                    <div className="hstack gap-2 justify-content-center">
                      <Button
                        style={{
                          fontFamily: "Gmarket-M",
                          backgroundColor: "#ff5a5f",
                          borderColor: "#ff5a5f",
                        }}
                        className="btn btn-success"
                        onClick={useConfirm(
                          `사용처리 하시겠습니까?`,
                          ticketUse,
                          () => {}
                        )}
                      >
                        직원확인
                      </Button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>

              <PurchaseTicketModal
                setVisible={setTicketModal}
                visible={ticketModal}
                info={selectOrder}
                setInfo={setSelectOrder}
                navigate={navigate}
                needRefresh={setdata}
              />
              <TicketGiftModal
                setVisible={setGiftModal}
                visible={giftModal}
                info={selectOrder}
                setInfo={setSelectOrder}
                navigate={navigate}
                needRefresh={setdata}
              />
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TicketPurchaseList;
