import React, { useEffect } from "react";
import {
  Card,
  Table,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Label,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { QRCodeSVG } from "qrcode.react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import TicketGiftModal from "../../../../Components/modal/TicketGiftModal";
import moment from "moment";
import { SUBDOMAIN } from "../../../../assets/meta/meta";
import QueryString from "qs";
import { getAccessToken } from "../../../../api/auth";
import {
  deleteTicketGiftById,
  getTicketSendDataByPage,
} from "../../../../api/service";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  padding: 10px 20px;
  /* max-width: 480px; */
  /* box-shadow: 3px 5px 5px hsl(0deg 0% 0% / 0.22); */
  background-color: white;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const FilterBadge = styled.div`
  border-radius: 100px;
  padding: 0.4rem 0.8rem;
  user-select: none;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#f6475f" : "#e9ecef")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  font-family: "Gmarket-M";
  font-size: 12px;
`;

function TicketSendGift(props) {
  const navigate = useNavigate();

  const [modalBackdrop, setModalBackdrop] = React.useState(false);
  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }
  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const [selectOrder, setSelectOrder] = React.useState({});
  const [orders, setOrders] = React.useState([]);
  const [giftModal, setGiftModal] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(false);
  const [queryString, setQueryString] = React.useState({
    filter_site: SUBDOMAIN,
    filter_state: "사용안함",
  });

  React.useEffect(() => {
    setdata();
  }, [queryString]);

  useEffect(() => {
    console.log("selectOrder", selectOrder);
  }, [selectOrder]);

  const setdata = async (page) => {
    try {
      if (!page) page = 1;
      const accessToken = getAccessToken();
      if (accessToken) {
        setIsLoading(true);

        let _queryString = QueryString.stringify(queryString);
        if (_queryString) _queryString = `&${_queryString}`;
        else _queryString = "";

        const response = await getTicketSendDataByPage({
          page: page,
          queryString: _queryString,
        });
        const data = response.data;

        setHasMore(data.hasMore);

        let _tickets = data.results;
        _tickets = _tickets.sort((a, b) => {
          return a.state === "사용안함" && !a.gifted ? -1 : +1;
        });
        _tickets = _tickets.map((ticket) => ({
          ...ticket,
          remaining: moment(ticket.expiration_date)
            .startOf("day")
            .diff(moment().startOf("day"), "days"),
        }));

        if (page === 1) {
          setPage(1);
          setOrders(_tickets);
        } else {
          setPage(page);
          setOrders((prev) => [...prev, ..._tickets]);
        }
      } else {
        navigate(`/`);
      }
    } catch (error) {
      console.log(error);
      // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
    } finally {
      setIsLoading(false);
    }
  };

  const handelCancelGift = (ticketId) => {
    if (window.confirm("티켓의 선물을 취소하시겠습니까?")) {
      deleteTicketGiftById(ticketId)
        .then((response) => {
          console.log(response.data);
          navigate(0);
        })
        .catch((error) => {
          console.log(error);
          // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
        });
    }
  };

  return (
    <React.Fragment>
      <Row>
        {/* <div className="d-flex justify-content-between">
                    <div>
                        <Input className="voucher-check-input-all" type="checkbox"
                            id="voucher-check-all"
                            onChange={() => {
                                toggleCheckAll()
                                changeCheckedRecords()
                            }}
                        />
                        <Label htmlFor="voucher-check-all" className="form-label fs-14" style={{ marginLeft: "6px" }}>전체선택</Label>
                    </div>
                    {modalDatas.length > 1 ? <Button onClick={() => {
                        setGiftModal(true);
                    }}>한번에 선물하기</Button> : null}
                </div> */}

        <Col className="col-12 d-flex flex-wrap justify-content-center gap-4">
          {(orders || []).map((order, key) => (
            <Col
              lg={12}
              xxl={12}
              md={12}
              sm={12}
              xs={12}
              key={order.id}
              className=""
            >
              <Item
                // visible={!order.gifted}
                visible={true}
                className="bg-white"
              >
                <div className="d-flex justify-content-between px-2 border-bottom">
                  <div
                    className="card-title pt-3"
                    style={{ fontWeight: "600" }}
                  >
                    {order.name}
                  </div>
                </div>
                <div className="d-flex py-3 align-items-center justify-content-center">
                  <div
                    className="mt-1"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div className="card-text text-muted">
                      기간: {order.expiration_date} 까지
                    </div>
                    <div className="card-text mt-2">
                      {order.remaining > 0
                        ? `잔여일수 ${order.remaining}일`
                        : "기간만료"}
                    </div>
                  </div>
                </div>
                {/* {order.bundleCount} */}
                {order.bundleCount && order.bundleCount > 1 && !order.can_refund_each ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ paddingBottom: "8px" }}
                  >
                    <div
                      style={{
                        paddingInline: "24px",
                        fontSize: "12px",
                        color: "#888",
                        width: "95%",
                        textAlign: "center",
                      }}
                    >
                      본 티켓은 행사 상품으로 전체 환불만 가능합니다.
                      <br />
                      묶음 중 1장이라도 사용, 선물 시 미사용분에 대해서도 환불이
                      불가능합니다.
                    </div>
                  </div>
                ) : null}
                {!order.gifted && order.state === "사용안함" ? (
                  <div className="d-flex w-100 flex-column align-items-center px-5 py-3">
                    <div className="d-flex justify-content-center gap-2 pb-2">
                      <Button
                        style={{
                          fontFamily: "Gmarket-M",
                          backgroundColor: "#fff",
                          borderColor: "#ff5a5f",
                          color: "#ff5a5f",
                        }}
                        color="success"
                        onClick={(e) => {
                          setSelectOrder(order);
                          setGiftModal(true);
                        }}
                      >
                        선물하기
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex w-100 flex-column align-items-center px-5 pb-3">
                    {order.gift && order.gift["username"] ? (
                      <div className="card-text text-muted">
                        받은이: {order.gift["username"]}
                      </div>
                    ) : null}

                    {order.gift.username === "" ? (
                      <span style={{ fontWeight: "700", color: "#ff5a5f" }}>
                        수락 대기중
                      </span>
                    ) : (
                      <span style={{ fontWeight: "700", color: "#ff5a5f" }}>
                        선물완료
                      </span>
                    )}
                    {/* {!!order.gifted ? <span>선물완료</span> : null} */}
                    <span style={{ margin: "8px" }}></span>
                    {order.gifted === "선물발신" ? (
                      <Button
                        style={{
                          fontFamily: "Gmarket-M",
                          backgroundColor: "#ff5a5f",
                          borderColor: "#ff5a5f",
                          color: "#fff",
                        }}
                        color="success"
                        onClick={(e) => {
                          handelCancelGift(order.id);
                        }}
                      >
                        선물취소
                      </Button>
                    ) : null}
                  </div>
                )}
              </Item>
            </Col>
          ))}

          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "50vh" }}
            >
              <Spinner animation="border" variant="primary" />
            </div>
          ) : null}

          {hasMore ? (
            <div
              className="d-flex justify-content-center"
              style={{ alignItems: "center", margin: "20px" }}
            >
              <Button color="primary" onClick={() => setdata(page + 1)}>
                더보기
              </Button>
            </div>
          ) : null}

          {!isLoading && orders.length === 0 ? (
            <div
              className="d-flex justify-content-center"
              style={{ alignItems: "center", height: 150 }}
            >
              선물 가능한 티켓이 없습니다.
            </div>
          ) : null}
        </Col>

        <TicketGiftModal
          setVisible={setGiftModal}
          visible={giftModal}
          info={selectOrder}
          setInfo={setSelectOrder}
          navigate={navigate}
          needRefresh={setdata}
        />
      </Row>
    </React.Fragment>
  );
}

export default TicketSendGift;
